.App {
  text-align: center;
}

.main {
  min-height: 100vh;
  display: flex;
  align-items: center;
  background-color: #fbe8a6;
  background: linear-gradient(to top right, #fbe8a6, #f4976c);
}

.weather-widget-container {
  border: 2px solid white;
  border-radius: 20px;
  padding: 30px;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.89);
  margin: 20px auto;
  max-width: 600px;
  box-shadow: 5px 5px 20px black;
}

/*========
Title
========*/

.app-title {
  color: white;
}

/*========
Search
========*/

.search-form input[type="text"] {
  /*override iOS defaults*/
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background: none;
  border: none;
  border-bottom: 2px solid white;
  color: white;
  margin-right: 10px;
  font-size: 16px;
}

.search-form input::placeholder {
  color: white;
}

.search-form button {
  background-color: #4a306c;
  color: white;
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 8px 15px;
  border: 2px solid #fff;
  font-weight: bolder;
  -webkit-transition: background-color 300ms linear;
  -moz-transition: background-color 300ms linear;
  -o-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
}

.search-form button:hover {
  background-color: white;
  color: #4a306c;
  border-radius: 20px;
  padding: 8px 15px;
  border: 2px solid #4a306c;
}

/*========
Forecast
========*/

.forecast-container {
  color: white;
}

.error {
  color: red;
  font-weight: 700;
}
